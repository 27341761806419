


































































import { Vue, Component, Watch } from 'vue-property-decorator';
@Component({
  name: 'chapterOrKnowledge'
})
export default class ChapterOrKnowledge extends Vue{
  $refs!: {chapterTree: any, knowledgeTree: any}
  private leftLoading = false;
  private checkedLoading = false;
  private subjectCode = '';
  private options: any = [{}, {}, {}];
  private async getSubject() {
    const res: any = await this.axios.post('/system/dictionary/querySubject');
    if (res.result) {
      Object.keys(res.json).forEach((key, index) => {
        let subjectParName = ''
        switch (key) {
          case 'high':
            subjectParName = '高中'
            break;
          case 'middle':
            subjectParName = '初中'
            break;
          case 'primary':
            subjectParName = '小学'
            break;
          default:
            subjectParName = '小学'
            break;
        }
        this.$set(this.options[index], 'value', key)
        this.$set(this.options[index], 'label', subjectParName)
        this.$set(this.options[index], 'children', res.json[key])
      });
      this.options.forEach((item: any, index: number) => {
        item.children.forEach((key: any) => {
          this.$set(key, 'value', key.code)
          this.$set(key, 'label', key.name)
        });
      });
      this.options = this.options.filter(i => i.children && i.children.length !== 0)
      this.subjectCode = this.options[0].children[0].value;
      this.getChapterOrKnowledgeTreeDate();
    }
  };

  private chapterTreeDateSet:any = [];
  private knowledgeDateSet:any = [];
  private getChapterOrKnowledgeTreeDate() {
    this.leftLoading = true;
    const list = [
      this.axios.post('/tiku/bookVersion/queryVresionBookTree', {subject: this.subjectCode}),
      this.axios.post('/tiku/knowledge/queryTree', {subjectId: this.subjectCode})
    ]
    this.axios.all(list).then((res:any) => {
      this.chapterTreeDateSet = res[0].json;
      this.knowledgeDateSet = res[1].json;
      this.checkedNodes = [];
      this.currentChapterData = null;
      this.leftLoading = false;
    })
  };
  private saveChapterKnowledge(chapterId, knowledgeIds, type) {
    this.axios.post('/tiku/chapterKnowledge/batchSave', {chapterId, knowledgeIds, type}, {headers: { 'Content-Type': 'application/json' }}).then((res:any) => {
      if (res.result) this.nodeClick(this.currentChapterData);
    })
  }

  private deleteKnowledge(chapterId, knowledgeIds) {
    this.axios.post('/tiku/chapterKnowledge/batchDelete', {chapterId, knowledgeIds}, {headers: { 'Content-Type': 'application/json' }}).then((res:any) => {
      res.result && this.nodeClick(this.currentChapterData);
    })
  }
  private chapterFilterText = ''
  private knowledgeFilterText = ''
  private checkedNodeKeys:Array<string> = []
  private checkedNodes:Array<object> = []
  private currentChapterData:any = null
  @Watch('chapterFilterText')
  private chapterFilterTextWatch(val) {
    this.$refs.chapterTree.filter(val);
  }
  @Watch('knowledgeFilterText')
  private knowledgeFilterTextWatch(val) {
    this.$refs.knowledgeTree.filter(val);
  }
  private filterNode(value, data) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }
  private nodeClick(data) {
    if (!data.childs?.length) {
      this.checkedLoading = true;
      this.currentChapterData = data;
      this.axios.post('/tiku/chapterKnowledge/queryKnowledgeByChapterId', {chapterId: data.id}).then((res: any) => {
        if (res.result) this.checkedNodes = res.json, this.checkedLoading = false, this.$refs.knowledgeTree.setCheckedNodes(this.checkedNodes)
      })
    }
  };

  private checkChaneg(data, { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }) {
    if (this.currentChapterData === null) {
      this.$message.warning('请选择末级章节');
      this.$refs.knowledgeTree.setChecked(data.id)
      return false;
    };
    let check = checkedKeys.findIndex( i => i === data.id);
    if (check !== -1 && data.childs && data.childs.length) {
      this.saveChapterKnowledge(this.currentChapterData.id, this.$refs.knowledgeTree.getCheckedKeys(true), 2);
    } else if (check !== -1 && (data.childs === null || data.childs.length === 0)) {
      this.saveChapterKnowledge(this.currentChapterData.id, [data.id], 1);
    } else if (check === -1 && data.childs && data.childs.length) {
      this.deleteKnowledge(this.currentChapterData.id, data.childs.map(i => i.id));
    } else if (check === -1 && (data.childs === null || data.childs.length === 0)) {
      this.deleteKnowledge(this.currentChapterData.id,[data.id]);
    }
  }

  private dragNode:any = null

  private drop(event: any) {
    if (this.currentChapterData === null) {
      this.$message.warning('请选择末级章节');
      return false;
    };
    this.saveChapterKnowledge(this.currentChapterData.id,[this.dragNode.data.id], 1)
  }

  protected created() {
    this.getSubject();
  }
}
